<template>
  <div class="info-one">
    <van-cell-group>
      <template #title><span class="group-title">以下基本资料，用于签订协议</span></template>
      <van-field
        clearable
        v-model="name"
        label="申请人名称"
        placeholder="请填写"
        input-align="right"
        maxlength="8"
        @blur="handleBlur"
        :formatter="formatterName"
        :required="true"
      />
      <van-field
        clearable
        v-model="idNo"
        label="证件号码"
        placeholder="请填写"
        input-align="right"
        maxlength="100"
        :formatter="formatterIdNo"
        :required="true"
      />
      <van-field
        clearable
        :placeholder="photoTip"
        input-align="right"
        maxlength="100"
        @click="handleUpdate"
        right-icon="arrow"
        readonly
      >
        <template #label>
          <span style="padding-left: 8px">证件照片</span>
        </template>
      </van-field>
      <van-field
        clearable
        v-model="email"
        label="电子邮箱"
        placeholder="请填写"
        input-align="right"
        maxlength="50"
        :required="true"
      />
    </van-cell-group>
    <van-cell-group>
      <template #title><span class="group-title">以下银行账号信息，用于向您汇款</span></template>
      <van-field
        clearable
        v-model="bankName"
        label="开户行"
        placeholder="请填写"
        input-align="right"
        maxlength="100"
        :formatter="formatterBankName"
        :required="true"
      />
      <van-field
        clearable
        v-model="bankAccount"
        label="户名"
        placeholder="请填写"
        input-align="right"
        maxlength="100"
        :formatter="formatterBankAccount"
        :required="true"
      />
      <van-field
        clearable
        v-model="bankNum"
        label="银行账号"
        placeholder="请填写"
        input-align="right"
        maxlength="100"
        :formatter="formatterBankNum"
        :required="true"
      />
    </van-cell-group>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" :disabled="disabledNext" @click="next">
        下一步
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button, CellGroup, Field } from 'vant';

export default {
  name: 'Agreement',
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
  },
  data() {
    return {
      name: '',
      idNo: '',
      idPhoto: 0,
      email: '',
      bankName: '',
      bankAccount: '',
      bankNum: '',
      isMounted: true,
    };
  },
  computed: {
    photoTip() {
      if (this.idPhoto === 0) return '选填';
      if (this.idPhoto === 1) return '已上传';
      return '';
    },
    disabledNext() {
      if (!this.name || this.name.trim() === '') return true;
      if (!this.idNo || this.idNo.trim() === '') return true;
      if (!this.email || this.email.trim() === '') return true;
      if (!this.bankName || this.bankName.trim() === '') return true;
      if (!this.bankAccount || this.bankAccount.trim() === '') return true;
      if (!this.bankNum || this.bankNum.trim() === '') return true;
      return false;
    },
  },
  async activated() {
    if (this.isMounted !== true) {
      const res = await this.$api.getStockInfo();
      if (res.code === 200) {
        this.idPhoto = res.data.idPhoto;
      }
    }
    this.$store.commit('setTitleValue', '资料填写');
    this.$store.commit('setStepsShow', false);
    this.isMounted = false;
  },
  async mounted() {
    const res = await this.$api.getStockInfo();
    if (res) {
      const { code, msg = '', data = {} } = res;
      if (code !== 200) {
        this.$toast(msg);
      } else {
        this.name = data.name;
        this.idNo = data.idNo;
        this.idPhoto = data.idPhoto;
        this.email = data.email;
        this.bankName = data.bankName;
        this.bankAccount = data.bankAccount;
        this.bankNum = data.bankNum;
        console.log(data, this.name, data.name, this);
      }
    }
  },
  methods: {
    formatterName(value) {
      return value.substr(0, 8);
    },
    formatterIdNo(value) {
      return value.substr(0, 18);
    },
    formatterBankName(value) {
      return value.substr(0, 50);
    },
    formatterBankAccount(value) {
      return value.substr(0, 50);
    },
    formatterBankNum(value) {
      return value.replace(/[^0-9]+/g, '').substr(0, 20);
    },
    handleUpdate() {
      this.$router.push({ path: '/upload' });
    },
    handleBlur(e) {
      this.bankAccount = e.target.value;
    },
    async next() {
      if (this.disabledNext) return;
      const submitForm = {
        name: this.name,
        idNo: this.idNo,
        idPhoto: this.idPhoto,
        email: this.email,
        bankName: this.bankName,
        bankAccount: this.bankAccount,
        bankNum: this.bankNum,
      };
      const { code, msg } = await this.$api.submitStockInfo(submitForm);
      if (code === 200) {
        this.$router.push({ name: 'Sign', params: { from: 'agreement' } });
      } else {
        this.$toast(msg);
      }
    },
  },
};
</script>

<style lang="less">
  .info-one {
    text-align: left;
    .group-title {
      font-size: 14px;
      opacity: 0.6;
    }
    .check-group {
      width: 200px;
      height: 60px;
      display: flex;
      justify-content: space-around;
      align-items: space-around;
      flex-wrap: wrap;
      & > div {
        flex-basis: 50%;
      }
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
    .icon {
      display: inline;
      position: relative;
      top: 3px;
      left: 3px;
    }
  }
</style>
